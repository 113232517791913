.cannot-access-form-container {
  width: 100%;
  min-height: 100vh;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cannot-access-form-text {
  font-family: Muli;
  font-weight: 900;
  font-size: 32px;
}

.cannot-access-form-illustration-container {
  width: 20%;
  height: 20%;

  margin-top: 2%;
  margin-bottom: 2%;
}
