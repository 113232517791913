.forgot-password-container {
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot-password-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 400px;
}

.forgot-password-form-header {
  width: 80%;

  margin-top: 5%;
  margin-bottom: 5%;

  text-align: center;

  font-family: Muli;
  font-size: 20px;
}

.forgot-password-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #66b2b2;
}

.forgot-password-textfield .MuiOutlinedInput-input {
  font-family: Muli;
}

.forgot-password-textfield label.Mui-focused {
  color: #66b2b2;
}

.forgot-password-dont-have-an-account-container {
  margin-top: 5%;

  font-family: Muli;
}

.forgot-password-instructions {
  width: 75%;

  font-family: Muli;
  font-size: 16px;
  text-align: left;

  margin-bottom: 8%;
}
