.share-root-container {
  width: 100%;
  min-height: 100vh;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: #f0f0f0b3;
}

.share-container {
  width: 80%;
}

.share-header {
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;

  margin-top: 3%;
}

.share-form-link-container {
  height: 130px;
  width: 60%;

  padding: 3%;

  margin-top: 3%;
  margin-bottom: 3%;
}

.share-form-link-header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.share-form-link-header {
  font-family: Muli;
  font-size: 16px;

  margin-left: 1%;
}

.share-embed-header {
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;

  margin-top: 3%;
}

.share-form-embed-options-list {
  margin-top: 3%;
}

.share-form-embed-options-card-container {
  height: 150px;
  width: 200px;

  padding: 3%;

  margin-bottom: 3%;
  margin-right: 3%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  cursor: pointer;
}

.share-form-embed-options-card-container:hover {
  background-color: #edf2f7;
}

.share-form-embed-option-card-image {
  width: 50px;
  height: 50px;
}

.share-form-embed-option-card-title {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
}

.share-warning-container {
  margin-top: 3%;

  background-color: rgb(255, 250, 235);
  color: rgb(100, 82, 22);
  border-radius: 8px;
  padding: 16px;

  font-family: Muli;
}

.share-customize-metadata-header {
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;

  margin-top: 3%;
}

.share-customize-metadata-container {
  height: 420px;
  width: 60%;

  padding: 3%;

  margin-top: 3%;
  margin-bottom: 3%;
}

.share-customize-metadata-header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: Muli;
  font-size: 16px;
}
