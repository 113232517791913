.connect-root-container {
  width: 100%;
  min-height: 100vh;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: #f0f0f0b3;
}

.connect-container {
  width: 80%;
}

.connect-header {
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;

  margin-top: 3%;
}

.connect-enable-email-notifications-container {
  width: 30%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 3%;
}

.connect-enable-email-notifications-text {
  font-family: Muli;
  font-size: 16px;
}

.connect-app-integrations-container {
  margin-top: 5%;
}

.connect-app-integrations-header {
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;

  margin-top: 3%;
}

.connect-app-integrations-list {
  margin-top: 3%;
}

.connect-app-integrations-card-container {
  height: 150px;
  width: 200px;

  padding: 3%;

  margin-bottom: 3%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  cursor: pointer;
}

.connect-app-integrations-card-container:hover {
  background-color: #edf2f7;
}

.connect-app-integration-card-image {
  width: 50px;
  height: 50px;
}

.connect-app-integration-card-title {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
}
