.pricing-root-container {
  margin: 1%;

  display: flex;
  justify-content: center;
}

.pricing-container {
  width: 70%;
}

.pricing-header {
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;

  margin-top: 3%;
  margin-bottom: 3%;
}

.pricing-plan-stats-container {
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
}

.pricing-plan-stats-header {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;

  margin-bottom: 3%;
}

.pricing-plan-stats-info-container {
  margin-bottom: 3%;
}

.pricing-plan-stats-info-header {
  font-family: Muli;
  font-size: 16px;

  margin-bottom: 1%;
}

.pricing-plan-stats-info {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
}

.pricing-plan-info-container {
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
}

.pricing-plan-info-header {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;

  margin-bottom: 3%;
}

.pricing-plan-info {
  font-family: Muli;
  font-size: 16px;
}
