.reset-password-container {
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-password-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 400px;
}

.reset-password-form-header {
  width: 80%;

  margin-top: 5%;
  margin-bottom: 5%;

  text-align: center;

  font-family: Muli;
  font-size: 20px;
}

.reset-password-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #66b2b2;
}

.reset-password-textfield .MuiOutlinedInput-input {
  font-family: Muli;
}

.reset-password-textfield label.Mui-focused {
  color: #66b2b2;
}

.reset-password-dont-have-an-account-container {
  margin-top: 5%;

  font-family: Muli;
}

.reset-password-instructions {
  width: 75%;

  font-family: Muli;
  font-size: 16px;
  text-align: left;

  margin-bottom: 8%;
}

.reset-password-link-expired-text {
  width: 75%;

  font-family: Muli;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.reset-password-link-expired-illustration-container {
  width: 50%;
  height: 50%;

  margin-top: 2%;
  margin-bottom: 2%;
}

.reset-password-link-support-text {
  font-family: Muli;
  font-size: 16px;
}
